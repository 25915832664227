<template>
  <image-upload
    @file-to-score="getScore"
    @image-uploaded="handleUploadedImage"
  ></image-upload>
  <status-display v-if="statusText" :statusText="statusText"></status-display>
  <items-display
    v-if="displayImage"
    :meanGrade="meanGrade"
    :receiptItems="receiptItems"
  ></items-display>
  <image-display v-if="displayImage" :imagePath="imagePath"></image-display>
</template>

<script>
import ImageUpload from './ImageUpload.vue';
import ItemsDisplay from './ItemsDisplay.vue';
import ImageDisplay from './ImageDisplay';
import StatusDisplay from './StatusDisplay.vue';

export default {
  components: {
    ImageUpload,
    ItemsDisplay,
    ImageDisplay,
    StatusDisplay,
  },
  data() {
    return {
      displayImage: false,
      imagePath: '',
      meanGrade: '',
      receiptItems: [],
      statusText: '',
    };
  },
  methods: {
    resetVariables() {
      this.meanGrade = '';
      this.receiptItems = [];
    },

    handleUploadedImage(file) {
      this.displayImage = true;
      this.imagePath = URL.createObjectURL(file);
    },

    async getScore(image) {
      this.resetVariables();
      this.statusText = 'Getting score please wait...';
      if (image == null) {
        this.statusText = 'Upload a valid receipt.';
        return;
      }
      const url = 'https://score-my-cart.nw.r.appspot.com/nutriscore';
      const api_key =
        'kmcd002y418SHSbxkspwpWLSKDHDYW68347GHS52Twhdhdus9s0wkwjxnzvzfAS417ehdid';
      let formData = new FormData();
      let headers = new Headers();

      const reader = new FileReader();
      reader.readAsDataURL(image);

      headers.append('x-token', api_key);
      formData.append('file', image);

      const requestOptions = {
        method: 'POST',
        // mode: 'cors',
        headers: headers,
        body: formData,
        redirect: 'follow',
      };

      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        if (data.mean_grade == "") {
          this.statusText = "Could not find items, please upload another receipt."
        }
        this.meanGrade = data.mean_grade;
        this.receiptItems = data.items;
      } catch (error) {
        console.log(error);
        this.statusText =
          'Failed to process image, please upload a valid receipt.';
      }
      this.statusText = '';
    },
  },
};
</script>
