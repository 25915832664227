<template>
  <base-card>
    <form @submit.prevent="handleGetScore">
      <p>Upload a picture of your grocery receipt to get a health score.</p>
      <input
        @change="handleFileChange"
        class="upload"
        type="file"
        id="imageInput"
        accept="image/*"
      />
      <base-button class="score" type="submit">Score</base-button>
    </form>
  </base-card>
</template>

<script>
export default {
  emits: ["image-uploaded", "file-to-score"],
  DataTransfer() {
    return {
      inputFile: null,
    }
  },
  methods: {
    handleFileChange(event) {
      this.inputFile = event.target.files[0];
      if (this.inputFile) {
        // Emit an event named 'image-uploaded' and send the selected file as payload
        this.$emit('image-uploaded', this.inputFile);
      }
    },
    handleGetScore() {
      this.$emit("file-to-score", this.inputFile)
    }
  },
};
</script>
