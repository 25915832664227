<template>
    <base-card>
        <img :src="imagePath" />
    </base-card>
</template>

<script>
export default {
    props: {
        imagePath: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

img {
  width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


</style>