<template>
  <base-card v-if="statusText">
    <p>{{ statusText }}</p>
  </base-card>
</template>

<script>
export default {
  props: {
    statusText: { type: String, required: true },
  },
};
</script>
