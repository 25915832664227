<template>
  <base-card v-if="meanGrade">
    <h3 v-if="meanGrade">Overall Nutriscore: {{ meanGrade }}</h3>
    <ul>
      <li v-for="item in receiptItems" :key="item.item">
        <base-card>
          <p>
            {{ item.item }}:
            <strong>
              {{ item.grade }}
            </strong>
          </p>
        </base-card>
      </li>
    </ul>
  </base-card>
</template>

<script>
export default {
  props: {
    meanGrade: {
      type: String,
      required: true,
    },
    receiptItems: {
      type: Object,
    },
  },
};
</script>

<style scoped>
h3, p {
  text-align: center;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: auto;
  max-width: 40rem;
}
</style>
