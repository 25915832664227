<template>
    <footer>
      <p>{{ title }}</p>
    </footer>
</template>
  
<script>
export default {
    props: {
        title: {type: String, required: true}
    }
}
</script>

<style scoped>
footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
}

footer p {
    color: rgb(99, 97, 97);
    font-size: x-small;
    margin: 0;
}

</style>