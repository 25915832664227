<template>
  <button :class="mode">
    <slot></slot>
    </button>
</template>

<script>

export default {
  props: ['mode']
}
</script>

<style scoped>

/*  */

button {
  padding: 0.6rem 1.5rem;
  border-radius: 14px;
  font-family: inherit;
  background-color: #a2d7b9;
  border: 1px solid #a2d7b9;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #659c7d;
  border-color: #659c7d;
  color: black;
}

.flat {
  background-color: transparent;
  color: #000000;
  border: none;
}

.flat:hover,
.flat:active {
  background-color: #edd2ff;
}
</style>