<template>
    <the-header title="Score my Cart"></the-header>
    <main-app></main-app>
    <the-footer title="There may be mistakes as its not 100% accurate."></the-footer>
</template>

<script>
import TheHeader from './components/layouts/TheHeader.vue'
import TheFooter from './components/layouts/TheFooter.vue'
import MainApp from './components/features/MainApp.vue'

export default {
    components: {TheHeader, TheFooter, MainApp}
}

</script>



<style>
* {
  box-sizing: border-box;
}

html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

body {
  margin: 0;
}
</style>