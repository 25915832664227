<template>
    <header>
      <h2>{{ title }}</h2>
    </header>
</template>
  
<script>
export default {
    props: {
        title: {type: String, required: true}
    }
}
</script>
<style scoped>
header {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
}

header h1 {
    color: rgb(0, 0, 0);
    margin: 0;
}

</style>